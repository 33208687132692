import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "../EnvConfig/env_config";


const initialState = {
    user: {         
        token: null,
        loginUserCode: null,
        userName: null,
    },
    isAuthenticated: false, 
    error: null,
    adminSessionDetails: {
        adminSessionExpires: false,
        status: '',
        message : ''
    }
};

let loginUserCode = localStorage.getItem('loginUserCode');
let token = localStorage.getItem('token');
let userName = localStorage.getItem('userName');

if (loginUserCode && token) {
    initialState.user = {
        token: token,
        loginUserCode: loginUserCode,
        userName: userName,
    };
    initialState.isAuthenticated = true;
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: { 
        setUser: (state, action) => {
            const { loginUserCode, token, userName } = action.payload;
            state.user = { loginUserCode, token, userName };    
            state.isAuthenticated = true; 
        },
        logOutUser: (state) => {
            state.user = {}; 
            state.isAuthenticated = false;
            localStorage.removeItem('loginUserCode',);
            localStorage.removeItem('loginToken');
            localStorage.removeItem('userName');
            localStorage.removeItem('emailID');
        },
        adminSessionExpire: (state, action) => {
            deleteCookie("adminLoginToken")
            deleteCookie("adminLoginUserCode")
            state.adminSessionDetails.adminSessionExpires = action.payload.adminSessionExpires    
            state.adminSessionDetails.status = action.payload.status 
            state.adminSessionDetails.message = action.payload.message    
        }
    }
});

export const { setUser, logOutUser, adminSessionExpire } = userSlice.actions;

export default userSlice.reducer;

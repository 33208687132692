import ExcelJS from 'exceljs';
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AdminApiCall } from "../../../EnvConfig/env_config";
import { adminSessionExpire } from "../../../features/authSlice";
import { AlertBox } from "../../Common/Input";
import Loader from "../../Common/Loader";

const Import = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [uploadExcelStatus, setUploadExcelStatus] = useState({
        totalData: "",
        sucessData: "",
        errorData: ""
    })
    const [errorRecords, setErrorRecords] = useState([]);
    const [successRecords, setSuccessRecords] = useState([]);
    const [showExcelStatus, setShowExcelStatus] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const dispatch = useDispatch();

    const GetData = async () => {
        let request = {
            Type: "DownloadPublishPlan"
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/import/DownloadImportFormat', { ...request })
            if (res.status === 'SUCCESS') {
                setExcelData(res.headers[0].split(","))
            } else if (res.status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.status,
                    message: res.Message
                }))
            }
            else if (res.status === 'ERROR') {
                AlertBox(res.status, 'Failed to show the data. Please try again.')
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        worksheet.addRow(excelData);

        // Optionally, add sample data
        worksheet.addRow(["Basic Plan", 100, true]);

        // Generate Excel file blob
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Trigger download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'PlanDetails.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    // Function to handle file upload
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file.name.endsWith('.xlsx')) {
            AlertBox('ERROR', 'Please upload a valid Excel file.')
            return;
        }
        setSelectedFile(file);
    };

    const resetFileInput = () => {
        setSelectedFile(null)
        setShowExcelStatus(false)
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };


    const uploadExcel = async () => {
        if (selectedFile) {
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(await selectedFile.arrayBuffer()); 

            const worksheet = workbook.worksheets[0]; 

            const uploadedData = [];
            worksheet.eachRow((row, rowIndex) => {
                if (rowIndex === 1) return; 
                uploadedData.push(row.values.slice(1)); 
            });

            const formattedData = uploadedData.map(row => {
                const dataObject = {};
                excelData.forEach((header, index) => {
                    dataObject[header] = row[index]; 
                });
                return dataObject;
            });
            let request = {
                PlanDetails: formattedData.map((item) => {
                    return {
                        PlanName: item?.PlanName,
                        Amount: item?.Amount?.toString(),
                        Publish: item?.Publish === true ? "true" : "false"
                    };
                })
            };

            try {
                setIsLoading(true)
                let res = await AdminApiCall('/api/import/ImportPlanDetails', { ...request })
                setShowExcelStatus(true)
                if (res.status === 'EXPIRED') {
                    dispatch(adminSessionExpire({
                        adminSessionExpires: true,
                        status: res.status,
                        message: res.Message
                    }))
                }
                await setSuccessRecords(res.successRecords)
                await setErrorRecords(res.errorRecords)
                setUploadExcelStatus({
                    ...uploadExcelStatus,
                    sucessData: res.totalNumberMergeRecords,
                    totalData: res.totalNumberRecords,
                    errorData: res.totalNumberErrorRecords,
                })
            }
            catch (error) {
                AlertBox('ERROR', 'Failed to show the data. Please try again.')
            }
            finally {
                setIsLoading(false)
            }
        } else {
            AlertBox('ERROR', 'Please upload file')
        }
    };

    const downloadStatusExcel = async (downloadStatus = [], name) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Plan Details');

        const excelData = Object.keys(downloadStatus[0]);
        worksheet.addRow(excelData); 
    
        downloadStatus?.forEach((item) => {
            const rowValues = excelData?.map((header) => item[header]); 
            worksheet.addRow(rowValues);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `PlanDetails${name}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    useEffect(() => {
        GetData()
    }, [])

    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text px-2'>{'Import'}</h4>
                        <div> <button onClick={() => downloadExcel()} className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" >
                            <i className="fa fa-download  me-1" />DownLoad
                        </button></div>
                    </div>

                    <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <p className='fw-bold'>Note</p>
                                        <ul>
                                            <li>1. Please Upload Only Excel File of Format .xlsx.</li>
                                            <li>2. Download Excel Format From Provided Link.
                                                <span
                                                    style={{
                                                        padding: "2px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "rgb(78, 45, 135)",
                                                        fontSize: "10px",
                                                        marginLeft: "5px"
                                                    }}
                                                    onClick={() => downloadExcel()}
                                                ><i className="fa fa-download color-success me-2" />DownLoad</span>

                                            </li>

                                        </ul>
                                        <div className="lms-new-button ">
                                            <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={() => uploadExcel()}>
                                                <i className="bi bi-check-lg " /> Upload
                                            </button>

                                            <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={() => resetFileInput()}>
                                                <i className="bi bi-arrow-clockwise" /> Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=" d-flex align-items-center justify-content-center">

                                            <label htmlFor="upload_xlsx" className="admin_upload_xlsx">
                                                <input
                                                    type="file"
                                                    id="upload_xlsx"
                                                    className="d-none"
                                                    accept=".xlsx"
                                                    ref={fileInputRef}
                                                    onChange={handleFileUpload}
                                                    style={{ marginTop: '20px' }}
                                                />
                                                <i className="fs-5 fa fa-upload" aria-hidden="true"></i>
                                                {
                                                    selectedFile ? <h6 className="m-0">{selectedFile.name}</h6> : <>
                                                        <h6 className="m-0">Upload File</h6>
                                                        <span>.xlsx</span>
                                                    </>
                                                }

                                            </label>


                                        </div>
                                        {
                                            showExcelStatus &&

                                            <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
                                                <div><span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "rgb(78, 45, 135)",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                >Total  : {uploadExcelStatus.totalData} </span> </div>
                                                <div><span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "#4BB543",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                    onClick={() => {
                                                        if (successRecords?.length !== 0) {
                                                            downloadStatusExcel(successRecords, 'success')
                                                        }
                                                    }}
                                                >
                                                    {
                                                        successRecords?.length !== 0 && <i className="fa fa-download color-success me-2" />
                                                    }

                                                    Success : {uploadExcelStatus.sucessData} </span> </div>
                                                <div> <span
                                                    style={{
                                                        padding: "3px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        backgroundColor: "#FF3333",
                                                        fontSize: "14px",
                                                        marginLeft: "5px"
                                                    }}
                                                    onClick={() => {
                                                        if (errorRecords?.length !== 0) {
                                                            downloadStatusExcel(errorRecords, 'error')
                                                        } else {
                                                            alert('kkkk')
                                                        }
                                                    }}
                                                >
                                                    {
                                                        errorRecords?.length !== 0 &&
                                                        <i className="fa fa-download color-success me-2" />
                                                    }
                                                    Error : {uploadExcelStatus.errorData}</span></div>

                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
            {isLoading && <Loader />}
        </div>

    )
}
export default Import;
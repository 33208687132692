import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { AdminApiCall } from '../../../EnvConfig/env_config';
import { adminSessionExpire } from '../../../features/authSlice';
import { handleDateChange, number, setFocus } from '../../Common/Common';
import { AlertBox, ConfirmAlertBox, DateBox, InputBox, SelectInputBox } from '../../Common/Input';
import Loader from '../../Common/Loader';
import { NewTable } from '../../Common/NewTable';

const DeviceMaster = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, settableData] = useState([]);
    const [groupNameDropdown, setGroupNameDropdown] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [filterData, setFilterData] = useState({
        type: "S",
        SearchText: "",
        searchFieldValue: "",
        startValue: "",
    })
    const [formData, setFormData] = useState({
        deviceID: '0',
        deviceType: '',
        brand: '',
        model: '',
        isActive: true,
    });
    const handleClear = () => {
        setFormData({
            ...formData,
            deviceID: '0',
            deviceType: '',
            brand: '',
            model: '',
            isActive: true,
        });
        setEdit(false)
        setFocus('txtDeviceType');
    }
    const dispatch = useDispatch()
    const getUserMasterData = async () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData.SearchFieldValue,
                "ComparisonType": filterData.StartValue,
                "SearchingText": filterData.SearchText,
            }],
            ...sortConfig,
            pageIndex: `${currentPageNo}`
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/Master/ShowDeviceMaster', { ...request, pageSize: pageSize })
            if (res.Status === 'SUCCESS') {
                settableData(res);
            } else if (res.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.Status,
                    message: res.Message
                }))
            }
            else if (res.Status === 'ERROR') {
                settableData([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    const handleSubmit = async () => {
        let request = {
            ...formData,
            Source: "Admin"
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall(`/api/Master/InsertUpdateDeviceMaster`, request);
            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message, response.focus)
                handleClear();
                getUserMasterData();
            } else if (response.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'ERROR') {
                setFocus(response.focus);
                AlertBox('ERROR', response.message)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleEdit = async (id) => {
        const request = {
            DeviceID: `${id}`,
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall('/api/Master/EditDeviceMaster', { ...request })
            console.log(response,'responseresponseresponse')
            if (response.status === 'SUCCESS') {
                setShowForm(true)
                setEdit(true)
                setFocus(response.focus);
                const { productPreviewUrl, focus, message, status, ...restResponse } = response;
                setFormData(restResponse);
            } else if (response.status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            }
            else if (response.status === 'ERROR') {
                AlertBox(response.status, response.message, response.focus)
            }
            else if (response.status === 'UNAUTHORIZED') {
                AlertBox(response.status, response.message, response.focus)
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }
    const handleDelete = async (id) => {
        const request = {
            DeviceID: `${id}`,
        }
        ConfirmAlertBox("warning", "Are you sure want to delete", async (confirmed) => {
            if (confirmed) {
                try {
                    setIsLoading(true)
                    const response = await AdminApiCall('/api/Master/DeleteDeviceMaster', { ...request })
                    if (response.status === 'SUCCESS') {
                        handleClear()
                        getUserMasterData();
                        setFocus(response.focus);
                        AlertBox('SUCCESS', response.message)
                    } else if (response.status === 'EXPIRED') {
                        dispatch(adminSessionExpire({
                            adminSessionExpires: true,
                            status: response.status,
                            message: response.message
                        }))
                    }
                    else if (response.status === 'ERROR') {
                        AlertBox(response.status, response.message, response.focus)
                    }
                    else if (response.status === 'UNAUTHORIZED') {
                        AlertBox(response.status, response.message, response.focus)
                    }
                }
                catch (error) {
                    AlertBox('ERROR', 'Failed to show the data. Please try again.')
                }
                finally {
                    setIsLoading(false)
                }
            }
        })
        return;

    }

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }

    const showFormHandle = async () => {
        await setShowForm(!showform)
        if (showform) {
            handleClear()
        }
        await setFocus("txtDeviceType")
    }


    useEffect(() => {
        document.title = 'Device Master'
        setFocus("txtDeviceType")
        getUserMasterData()
    }, [currentPageNo, sortConfig, pageSize])


    return (
        < >       <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text'>{'Device Master'}</h4>
                        <div> {showform ? (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="fa-solid fa-x  me-1" />Close Form
                        </button>) : (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="bi bi-check-lg " /> Show Form
                        </button>)}</div>
                    </div>
                    {showform ? <>
                        <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row ">

                                        <SelectInputBox
                                            id="txtDeviceType"
                                            divclassname="col col-lg-3 fields mt-3"
                                            label="Device Type"
                                            required={true}
                                            value={formData.deviceType ? formData.deviceType : ""}
                                            onChange={(e) => {
                                                setFormData({ ...formData, deviceType: e.target.value })
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtBrandName")
                                                }
                                            }}
                                        >
                                            <option>--Select Device Type--</option>
                                            <option value={'Smart Mobile'}>{'Smart Mobile'}</option>
                                            <option value={'Smart Tablet'}>{'Smart Tablet'}</option>
                                            <option value={'Smart Watch'}>{'Smart Watch'}</option>
                                        </SelectInputBox>
                                        <InputBox
                                            label='Brand Name'
                                            id='txtBrandName'
                                            maxLength='30'
                                            placeholder="Brand Name"
                                            value={formData.brand}
                                            onChange={(e) => setFormData({ ...formData, brand: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtModel")
                                                };
                                            }}
                                        />
                                        <InputBox
                                            label='Model'
                                            id='txtModel'
                                            maxLength='30'
                                            placeholder="Model"
                                            value={formData.model}
                                            onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("activeCheckbox")
                                                };
                                            }}
                                        />
                                        <div className="col-lg-3 col-md-4 col-sm-6 d-flex  align-items-end">
                                            <div className="form-check form-switch ">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="activeCheckbox"
                                                >
                                                    Active
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="activeCheckbox"
                                                    name='active'
                                                    checked={formData.isActive}
                                                    onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit();
                                                        };
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button ">
                                                {edit ? (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Update
                                                </button>) : (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg " /> Save
                                                </button>)}

                                                <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <></>}

                </section>

                <NewTable handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    PageName={'DeviceMaster'}
                    handleFilter={() => {
                        getUserMasterData()
                        setFilterData({
                            ...filterData,
                        })
                    }}
                    Filter = {false}
                    filterData={filterData}
                    tableData={tableData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
            </main>
            {isLoading && <Loader />}
        </div></>
    )
}

export default DeviceMaster;
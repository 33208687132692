import ExcelJS from 'exceljs';
import * as debounce from 'lodash/debounce';
import { Cookies } from 'react-cookie';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from "xlsx";
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from './Input';



export const dateFormate = (value) => {
    let dateArr = value?.toString()?.split(' ');
    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
    return dateArr;

}

export const dateFormateWithTime = (value) => {
    let dateArr = value?.toString()?.split(' ');
    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]} ${dateArr[4]}`
    return dateArr;

}

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}

export const ReverseFormatDate = (inputDate) => {
    let convertedDate = ''
    if (inputDate !== '') {
        const [day, month, year] = inputDate.split('-');
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const monthIndex = months.findIndex(m => m === month);
        convertedDate = new Date(year, monthIndex, day);
    }
    return convertedDate;
}

export const ClearSession = () => {
    const cookies = new Cookies();
    const navigate = useNavigate()
    cookies.remove('token')
    cookies.remove('loginUserCode')
    cookies.remove('userName')
    cookies.remove('CookieConsentByFineshop')
    cookies.remove('csrftoken')
    cookies.remove('salesPersonCode')

    setTimeout(() => {
        navigate('/login');
    }, 1000)
}

export const exportToExcel = (data, fileName) => {
    try {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);    
    } catch (error) {
        console.error("Error exporting to Excel:", error);
    }
}

export const exportToExcelHeader = async (handleApiUrl, data, fileName, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {
    const cookies = new Cookies();
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const apiUrl = handleApiUrl;
    const requestBody = data;
    requestBody.token = Token;

    const loginCodeString = String(LoginUserCode);
    requestBody.loginUserCode = loginCodeString;

    const requestHeaders = {
        'Content-Type': 'application/json',

    };
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });
        const res = await response.json();
        if (res.Status === "SUCCESS") {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1', {
                views: [{ state: 'frozen', ySplit: 1 }],
            });
            const headerRow = worksheet.addRow(res?.HeadersKey?.length > 0
                ? res.HeadersKey.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res?.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                ))


            const headerKey = (res?.HeadersKey?.length > 0 && res?.HeadersValue?.length > 0)
                ? res.HeadersValue.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                );


            headerRow.font = { bold: true };
            if (enableHeaderColor) {
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'AAAAAA' },
                };
            }
            if (enableFilters) {
                worksheet.autoFilter = {
                    from: { row: 1, column: 1 },
                    to: { row: 1, column: headerKey.length },
                };
            }
            for (let i = 2; i <= res.Data.length + 1; i++) {
                const row = worksheet.getRow(i);

                if (enableAlternateColor && i % 2 === 0) {
                    row.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DDDDDD' },
                    };
                }

                row.values = res.HeadersValue?.length > 0 ?
                    res.HeadersValue?.map((key) => res.Data[i - 2][key])
                    : headerKey?.map((key) => res.Data[i - 2][key]);
            }

            // Set column widths to fit content
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? String(cell.value).length : 0;
                    maxLength = Math.max(maxLength, length);
                });
                column.width = maxLength < 12 ? 12 : maxLength;
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else if (res.Status === 'ERROR') {

        }
        else if (res.Status === 'UNAUTHORIZED') {

        }
    } catch (err) {

    }
};

const AutoComplete = async ({ setOnState, Type, Prefix, ContextKey = '', ContextKey2 = '' }) => {
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode').toString();
    let data = { Token, LoginUserCode, Type, Prefix, ContextKey, ContextKey2 }

    await fetch(`${API_SERVER}/api/AutoComplete/AutoComplete`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(async (response) => {
            let res = await response.json()
            if (res.status === "SUCCESS") {
                setOnState(res.autoCompleteData?.map(item => item.values));
            }
            else if (res.message === "EXPIRED") {
                ClearSession();
            }
        }).catch((err) => {
            toast.error(err, {
                position: 'top-center',
            })
        });
}

export const BindAutoCompleteOnLoad = async ({ setOnState, Type, Prefix, ContextKey = '', ContextKey2 = '' }) => {
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode').toString();
    let data = { Token, LoginUserCode, Type, Prefix, ContextKey, ContextKey2 }

    await fetch(`${API_SERVER}/api/AutoComplete/AutoComplete`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(async (response) => {
            let res = await response.json()
            setOnState([])
            if (res.status === "SUCCESS") {
                setOnState(res.autoCompleteData?.map(item => item.values));
            }
            else if (res.message === "EXPIRED") {
                ClearSession();
            }
        }).catch((err) => {
            toast.error(err, {
                position: 'top-center',
            })
        });
}

export const BindAutoComplete = debounce(AutoComplete, 500);

export const Amount = (Value) => {
    return Value.replace(/[^0-9.]/g, '').replace(/(\.\d\d)\d+/, '$1');
}

export const number = (Value) => {
    return Value.replace(/[^0-9]/g, '');
}

export const numberwithDot = (Value) => {
    Value = Value.replace(/[^0-9.]/g, '')
    return Value;//.replace(/[^0-9]/g, '');
}

export const setFocus = (ID) => {
    const element = document.getElementById(ID);
    if (element) {
        element.focus()
    }
}

export const setDisabled = (ID, status) => {
    const element = document.getElementById(ID);
    if (element) {
        element.disabled = status;
    }
};

export const GetDate = (Day) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + Day);
    return formatDate(currentDate);
}

export const OpenBase64Pdf = (pdfBase64, DocName, Target) => {
    if (window.innerWidth <= 767) {
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:application/pdf;base64,${pdfBase64}`;
        downloadLink.download = `${DocName}.pdf`;
        downloadLink.click();
    } else {
        const pdfWindow = window.open('', Target);
        if (pdfWindow) {
            pdfWindow.document.write(`
                <html>
                    <head>
                        <title>SimFly PDF Viewer</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    </head>
                    <body style="margin:0;">
                        <embed width="100%" height="100%" src="data:application/pdf;base64,${pdfBase64}#zoom=300" type="application/pdf">
                    </body>
                </html>
            `);
        }
    }
}

export const setTitle = async (PageUrl, setOpen) => {
    if (window.innerWidth < 1200) {
        setOpen(false)
    }
    const cookies = new Cookies();
    const Token = await cookies.get('token').toString();
    const LoginUserCode = await cookies.get('loginUserCode').toString();
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode,
        PageUrl: PageUrl,
    }
    await fetch(`${API_SERVER}/api/User/ValidateUserPageAccess`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    }
    ).then(async (response) => {
        let res = await response.json();
        if (res.status === "SUCCESS") {
            document.title = res.menuText;
        }

    })
}

export const FileUploadChildExcel = (props) => {
    const { label = '', message = '', types = [], children } = props;
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
            <div className="sc-eqUAAy file-children">
                {(message.trim() === '.' || message.trim() === '') ?
                    <span className="sc-fqkvVR"><span>{`${'Upload'} ${label}`}</span>{'or drop a file right here'} </span> :
                    <span className="sc-fqkvVR"><span className='text-success'>{`${message}`}</span>{'uploaded successfully!'}</span>
                }
                {(message.trim() !== '.' || message.trim() !== '') &&
                    <span title={`types: ${types.join()}`} className="file-types text-info">{types.join()}</span>
                }
                {children}
            </div>
        </>
    );
}

export const FileUploadChild = (props) => {
    const { label = '', message = '', types = [], children } = props;

    return (
        <>
            <i className="fa-solid fa-upload" style={{ color:"#4E2D87" }}></i>
            <div className="sc-eqUAAy file-children ">
                {(message.trim() === '.' || message.trim() === '') ?
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span style={{ fontSize: "12px" }}>{`Upload ${label}`}</span>{label === "Category Image" || label === "Sub Category Image" || label === "Banner Image" ? "" : "or drop a file right here"} </span> :
                    <span className="sc-fqkvVR" style={{ fontSize: "12px" }}><span className=' text-success'>{`${message}`}</span >Uploaded</span>
                }
                {(message.trim() !== '.' || message.trim() !== '') &&
                    <span style={{ fontSize: "12px" }} title={`types: ${types.join()}`} className="file-types text-info">{types.join()}</span>
                }
                {children}
            </div>
        </>
    );
}

export const ProductHeader = (props) => {
    const { onMainButtonClick, onExportButtonClick, showForm, heading, buttonText, ShowFilter, isExpanded, toggleFilter, onFilterButtonClick } = props;

    return (
        <>
            <div className={` pt-3 heading-box1 ${!showForm ? '' : 'pb-0  '}`}>

                {buttonText ?
                    <div style={{ float: "right", gap: "4px" }}>
                        <button
                            className={!showForm ? 'btn add-btn text-white ms-4' : 'btn btn-danger'}

                            onClick={onMainButtonClick}
                        >
                            {showForm ? (
                                <>
                                    <span style={{ borderRight: '2px solid white', paddingRight: '5px', marginRight: '3px' }}><i className='fa fa-arrow-left' /></span> Back
                                </>
                            ) : (
                                <>
                                    <span style={{ borderRight: '2px solid white', paddingRight: '2px', marginRight: '5px' }}> <i className='fa fa-plus' /> </span> {buttonText}
                                </>
                            )}
                        </button>

                    </div>
                    : <> </>}

                {!showForm && (
                    <div style={{ float: "right" }}>
                        <button className="btn Exp-btn text-white" onClick={onExportButtonClick}>
                            <i className="bi bi-download" /> Export
                        </button>
                    </div>
                )}
                {!showForm &&(
                    <div style={{ float: "right", marginRight: "20px" }}>
                        <button className="btn text-white btn Exp-btn text-white" onClick={onFilterButtonClick}>
                            {!isExpanded ? 'Hide Filter' : 'Show Filter'}
                        </button>
                    </div>
                )}
                <div className="heading">
                    <h6 className="m-0 font-weight-bold text-primary">{heading}</h6>
                </div>
            </div>
        </>
    );
};

export const ImportButton = ({ BulkSubmit, handleBulk, downloadFormat, children, Status = [], ErrorData }) => {
    return (
        <div className='border border-2 p-2 w-100' style={{ borderRadius: '8px' }}>
            <label className="form-label" htmlFor={'import'}>{'Bulk Upload'}</label>
            <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}>
                <FileUploader handleChange={BulkSubmit} name="file" types={["xlsx"]} />
            </div>
            <div className='mt-2'>
                <button type="button" className="button btn apply-btn me-3 mb-0 mt-2" style={{ minWidth: '8px', backgroundColor: "#689D15", color: "white" }} onClick={handleBulk}>
                    <i className="fa fa-upload text-white color-success"></i> Upload
                </button>
                <button type="button" className="btn Exp-btn text-white me-3 mb-0 mt-2" style={{ minWidth: '8px', textDecoration: 'underline' }} onClick={downloadFormat}>
                    Download Format <i className="fa fa-download color-success"></i>
                </button>
            </div>
            <div className='d-flex mb-1'>
                {Status?.length !== 0 ? (
                    <div className='text-success p-0 pt-2 m-0 fw-bold' style={{ lineHeight: '14px' }}>
                        Success : {Status?.SuccessCount} &nbsp; &nbsp;
                        <span className='text-danger'>
                            Error : {Status?.ErrorCount}
                            {Status?.length !== 0 && Status?.ErrorData?.length !== 0 ? (
                                <>&nbsp;<i className="fa fa-download color-success" style={{ cursor: 'pointer' }} onClick={ErrorData}></i></>
                            ) : ""}
                        </span>
                    </div>
                ) : ""}
            </div>
            {children}
        </div>
    )
}

export const HandleBulkInsertData = async (url, Records, Token, LoginUserCode) => {
    let SuccessCount = 0
    let ErrorData = []
    let ErrorCount = 0
    if (Records.length !== 0) {
        for (let i = 0; i < Records.length; i++) {
            let stringifiedRecord = {};
            for (const key in Records[i]) {
                if (Object.hasOwnProperty.call(Records[i], key)) {
                    stringifiedRecord[key] = Records[i][key].toString();
                }
            }
            let response = { Token, LoginUserCode, ...stringifiedRecord };
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(response),
                headers: {
                    "Content-Type": "application/json"
                },
                // eslint-disable-next-line no-loop-func
            }).then(async (resp) => {
                let res = await resp.json();
                if (res.Status === "SUCCESS") {
                    SuccessCount = SuccessCount + 1
                    AlertBox(res.status, res.message, res.focus);
                } else if (res.status === 'ERROR') {
                    ErrorData.push({ ...Records[i], ERROR: res.message })
                    ErrorCount = ErrorCount + 1
                }
            }
            )
        }
    } else {
        alert('No Record Found !')
    }

    return { SuccessCount, ErrorCount, ErrorData }
}

export const DownloadFileAtURL = (File) => {
    let FileName = `/${File}`
    const fileName = FileName.split('/').pop()
    const aTag = document.createElement('a');
    aTag.href = FileName
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
}

export const ERRORDATALIST = (Status, FileName) => {
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet([
        (Object.keys(Status.ErrorData[0]))
        ,
        ...Status?.ErrorData?.map((records) =>
            Object.keys(Status.ErrorData[0])?.map((data, i) =>
                records[data]
            )
        ),
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${FileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

}

export const BulkClearData = () => {
    let a = document.querySelectorAll('.jRuVVs');
    if (a.length > 0) {
        a[0].innerHTML = 'Upload  or drop a file right here'
        a[0].innerHTML = a[0].innerHTML.replace('Upload', '<span className="text-decoration-underline">Upload</span>');
    }
}

export const handleDateChange = (e, setFormData, formData, key) => {
    let dateArr = (e?.toString() || '').split(' ');
    if (dateArr.length >= 4) {
        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`;
        setFormData({ ...formData, [key]: dateArr });
    } else {
        setFormData({ ...formData, [key]: '' });
    }
};

export function convertToNumber(value) {
    return value.replace(/[^0-9]/g, '');
}

export function upperCase(inputString) {
    return inputString.toUpperCase();
}

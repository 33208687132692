import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { AdminApiCall } from '../../../EnvConfig/env_config';
import { adminSessionExpire } from '../../../features/authSlice';
import { FileUploadChild, handleDateChange, number, setFocus } from '../../Common/Common';
import { AlertBox, ConfirmAlertBox, DateBox, InputBox, SelectInputBox } from '../../Common/Input';
import Loader from '../../Common/Loader';
import { NewTable } from '../../Common/NewTable';
import { FileUploader } from "react-drag-drop-files";


const PromotionMaster = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, settableData] = useState([]);
    const [groupNameDropdown, setGroupNameDropdown] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [filterData, setFilterData] = useState({
        type: "S",
        SearchText: "",
        searchFieldValue: "",
        startValue: "",
    })
    const [formData, setFormData] = useState({
        promotionName: '',
        promotionType: '',
        minQuantity: '',
        discountType: 'Percentage',
        discountValue: '',
        startDate: '',
        endDate: '',
        image: '',
        planImageBase64: '',
        imageExt: '',
        imageName: '',
        isActive: true,
    });
    const handleClear = () => {
        setFormData({
            promotionName: '',
            promotionType: '',
            minQuantity: '',
            discountType: 'Percentage',
            discountValue: '',
            startDate: '',
            endDate: '',
            image: '',
            planImageBase64: '',
            imageExt: '',
            imageName: '',
            isActive: true,
        });
        setEdit(false)
        setFocus('txtPromotionName');
    }
    const dispatch = useDispatch()
    const getUserMasterData = async () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData.SearchFieldValue,
                "ComparisonType": filterData.StartValue,
                "SearchingText": filterData.SearchText,
            }],
            ...sortConfig,
            pageIndex: `${currentPageNo}`
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/Master/ShowPromotionsMaster', { ...request, pageSize: pageSize })
            if (res.Status === 'SUCCESS') {
                settableData(res);
            } else if (res.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.Status,
                    message: res.Message
                }))
            }
            else if (res.Status === 'ERROR') {
                settableData([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        let request = {
            ...formData,
            Source: "Admin"
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall(`/api/Master/InsertUpdatePromotionsMaster`, request);
            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message, response.focus)
                handleClear();
                getUserMasterData();
            } else if (response.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'ERROR') {
                setFocus(response.focus);
                AlertBox('ERROR', response.message)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleEdit = async (id) => {
        const request = {
            PromotionID: `${id}`,
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall('/api/Master/EditPromotionsMaster', { ...request })
            if (response.status === 'SUCCESS') {
                setShowForm(true)
                setEdit(true)
                setFocus(response.focus);
                const { productPreviewUrl, focus, message, status, ...restResponse } = response;
                setFormData(restResponse);
            } else if (response.status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            }
            else if (response.status === 'ERROR') {
                AlertBox(response.status, response.message, response.focus)
            }
            else if (response.status === 'UNAUTHORIZED') {
                AlertBox(response.status, response.message, response.focus)
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }
    const handleDelete = async (id) => {
        const request = {
            PromotionID: `${id}`,
        }
        ConfirmAlertBox("Warning", "Are you sure want to delete", async (confirmed) => {
            if (confirmed) {
                try {
                    setIsLoading(true)
                    const response = await AdminApiCall('/api/Master/DeletePromotionsMaster', { ...request })
                    if (response.status === 'SUCCESS') {
                        handleClear()
                        getUserMasterData();
                        setFocus(response.focus);
                        AlertBox('SUCCESS', response.message)
                    } else if (response.status === 'EXPIRED') {
                        dispatch(adminSessionExpire({
                            adminSessionExpires: true,
                            status: response.status,
                            message: response.message
                        }))
                    }
                    else if (response.status === 'ERROR') {
                        AlertBox(response.status, response.message, response.focus)
                    }
                    else if (response.status === 'UNAUTHORIZED') {
                        AlertBox(response.status, response.message, response.focus)
                    }
                }
                catch (error) {
                    AlertBox('ERROR', 'Failed to show the data. Please try again.')
                }
                finally {
                    setIsLoading(false)
                }
            }
        })
        return;

    }

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }

    const showFormHandle = async () => {
        await setShowForm(!showform)
        if (showform) {
            handleClear()
        }
        await setFocus("txtPromotionName")
    }


    useEffect(() => {
        document.title = 'Promotion Master'
        setFocus("txtPromotionName")
        getUserMasterData()
    }, [currentPageNo, sortConfig, pageSize])


    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3  d-flex justify-content-between'>
                        <h4 className='primary-text'>{'Promotion Master'}</h4>
                        <div> {showform ? (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="fa-solid fa-x  me-1" />Close Form
                        </button>) : (<button className="hideshowbtn hideshowbtninside secondary-bghideshowbtn text-dark me-2" onClick={showFormHandle}>
                            <i className="bi bi-check-lg " /> Show Form
                        </button>)}</div>
                    </div>
                    {showform ? <>
                        <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row ">

                                        <InputBox
                                            label='Promotion Name'
                                            id='txtPromotionName'
                                            maxLength='30'
                                            placeholder="Promotion Name"
                                            value={formData.promotionName}
                                            onChange={(e) => setFormData({ ...formData, promotionName: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtPromotionType")
                                                };
                                            }}
                                        />
                                        <div className="col col-lg-3 fields mt-3">
                                            {/*<p style={{ marginBottom :"0px" }}>gdfgyufbg</p>*/}
                                            <label>Promotion Image</label>
                                        <FileUploader
                                            classes="file-uploader emp-photo"
                                            handleChange={(file) => {
                                                let extArr = (file.name).split(".");
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        let logo = (reader.result).split(',')[1];
                                                        setFormData({
                                                            ...formData,
                                                            image: logo,
                                                            planImageBase64: logo,
                                                            imageExt: extArr[1],
                                                            imageName: extArr[0],
                                                        });
                                                    };
                                                }
                                            }}
                                            name="file"
                                            types={["JPG", "PNG"]}
                                            children={
                                                <FileUploadChild
                                                    label=""
                                                    message={formData.imageName + '.' + formData.imageExt}
                                                    types={["JPG", "PNG"]}
                                                />
                                            }
                                            />
                                        </div>
                                        <SelectInputBox
                                            id="txtPromotionType"
                                            divclassname="col col-lg-3 fields mt-3"
                                            label="Promotion Type"
                                            required={true}
                                            value={formData.promotionType ? formData.promotionType : ""}
                                            onChange={(e) => {
                                                setFormData({ ...formData, promotionType: e.target.value })
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.promotionType === 'Bulk Discount') {
                                                        setFocus("txtMinQuantity")
                                                    } else {
                                                        setFocus("txtDiscountType")
                                                    }
                                                }
                                            }}
                                        >
                                            <option>--Choose Promotion Type--</option>
                                            <option value={'Bulk Discount'}>Bulk Discount</option>
                                            <option value={'Double Data'}>Double Data</option>
                                        </SelectInputBox>
                                    
                                        <InputBox
                                            label='Min Quantity'
                                            id='txtMinQuantity'
                                            maxLength='30'
                                            placeholder="Min Quantity"
                                            value={formData.minQuantity}
                                            onChange={(e) => setFormData({ ...formData, minQuantity: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtDiscountType")
                                                };
                                            }}
                                        />

                                        {
                                            formData.promotionType === 'Bulk Discount' && <>
                                                  <SelectInputBox
                                            id="txtDiscountType"
                                            divclassname="col col-lg-3 fields mt-3"
                                            label="Discount Type"
                                            required={true}
                                            value={formData.discountType ? formData.discountType : ""}
                                            onChange={(e) => {
                                                setFormData({ ...formData, discountType: e.target.value })
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtDiscountValue")
                                                }
                                            }}
                                        >
                                            <option value={'Percentage'}>Percentage</option>
                                            <option value={'Amount'}>Amount</option>
                                        </SelectInputBox>
                                       
                                        <InputBox
                                            label='Discount Value'
                                            id='txtDiscountValue'
                                            maxLength='30'
                                            placeholder="Discount Value"
                                            value={formData.discountValue}
                                            onChange={(e) => setFormData({ ...formData, discountValue: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtStartDate")
                                                };
                                            }}
                                        />
                                            </>
                                        }
                                      
                                       
                                        <DateBox
                                            id='txtStartDate'
                                            divclassname="col col-lg-3 fields mt-3"
                                            label='Start Date'
                                            placeholder='Start Date'
                                            selected={formData.startDate === '' ? '' : new Date(formData.startDate)}
                                            onChange={(e) => handleDateChange(e, setFormData, formData, 'startDate')}
                                            autoComplete="off"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus('txtEndDate')
                                                }
                                            }}
                                        />
                                        <DateBox
                                            id='txtEndDate'
                                            divclassname="col col-lg-3 fields mt-3"
                                            label='End Date'
                                            placeholder='End Date'
                                            selected={formData.endDate === '' ? '' : new Date(formData.endDate)}
                                            onChange={(e) => handleDateChange(e, setFormData, formData, 'endDate')}
                                            autoComplete="off"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus('isActive')
                                                }
                                            }}
                                        />
                                        <div style={{ marginTop: formData.promotionType === 'Bulk Discount' ? "15px" : '0px' }} className="col-lg-3 col-md-4 col-sm-6 d-flex  align-items-end">
                                            <div className="form-check form-switch ">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="isActive"
                                                >
                                                    Active
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="isActive"
                                                    name='active'
                                                    checked={formData.isActive}
                                                    onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit();
                                                        };
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button ">
                                                {edit ? (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Update
                                                </button>) : (<button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg " /> Save
                                                </button>)}

                                                <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <></>}

                </section>

                <NewTable handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    PageName={'PromotionMaster'}
                    handleFilter={() => {
                        getUserMasterData()
                        setFilterData({
                            ...filterData,
                        })
                    }}
                    filterData={filterData}
                    tableData={tableData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
            </main>
            {isLoading && <Loader />}
        </div>

    )
}

export default PromotionMaster







import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { toast } from 'react-toastify';
import { ApiCall } from "../../EnvConfig/env_config";
import { addToCart, setCartModal } from "../../features/addToCartSlice";
import MiniLoader from "./MiniLoader";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';

const ModalData = ({ onClose, detailsModal, NetworkID, planId, country }) => {
    const [singleDestinationData, setSingleDestinationData] = useState([])
    const [singleDestinationDataForAPI, setSingleDestinationDataForAPI] = useState({})
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)
    const [checkNetwork, setCheckNetwork] = useState(false)
    const [checkCoverage, setCheckCoverage] = useState(false)

    let settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: checkCoverage ? 'auto' : '600px', // Conditional width directly applied
        backgroundColor: 'background.paper', // Use theme's paper background color
        boxShadow: 24,
        padding: 3,
        height: checkCoverage ? 'auto' : 500,
        overflowY: 'auto',
    };

    const loadCartFromStorage = () => {
        const cart = localStorage.getItem('cartProduct');
        return cart ? JSON.parse(cart) : [];
    };

    const handleBuyNow = () => {
        let cart = loadCartFromStorage();
        let UpdatedData = {
            ...singleDestinationDataForAPI,
            quantity: 1,
            networkID: singleDestinationData[0]?.operatorList.map(op => op.operatorId).join('|'),
            network: singleDestinationData[0]?.operatorList?.map(op => op.operatorName).join("|")
        }
        const existingIndex = cart.findIndex(item => item.planID === UpdatedData.planID);
        if (existingIndex !== -1) {
            toast.error("Packages already in your cart");
            onClose()
            dispatch(setCartModal(true))
            return;
        } else {
            cart.push(UpdatedData);
        }
        localStorage.setItem('cartProduct', JSON.stringify(cart));
        dispatch(addToCart(cart))
        toast.success("Data packages added to your cart successfully!");
        onClose()
        dispatch(setCartModal(true))
    }

    const fetchAllResponse = async () => {
        let request = {
            "Type": "Details",
            "NetworkID": NetworkID,
            "planID": planId,
            "PackageType": "",
            "Data": "",
            "Validity": "",
            "PriceRange": "",
            Country: country,
        }
        try {
            const response = await ApiCall(`/api/Web/GetPlanDetails`, request);
            if (response.status === "SUCCESS") {
                await setSingleDestinationData(response.planDetail)
                await setSingleDestinationDataForAPI(response.planDetail[0])
                await setLoader(false)
            } else if (response.status === 'ERROR') {
                setLoader(false)
                toast(response.Message)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try after sometimes.');
        }
    }

    useEffect(() => {
        fetchAllResponse()
    }, [])

    return (
        <>
            <div className={`modal fade sim_modal ${detailsModal ? 'show' : ''}`} style={{ display: detailsModal ? 'block' : 'none', }} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    {
                        !loader ?
                            <div className="modal-content">
                                <button type="button" onClick={onClose} className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="modal-body p-0">
                                    <div className="model_header">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-md-6 text-center">
                                                <img
                                                    className="br-12"
                                                    src={`data:image/jpg;base64,` + singleDestinationData[0]?.planImage}
                                                    alt={singleDestinationData[0]?.coverage}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = './assets/images/image_not_available.png'; }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div onClick={() => {
                                                    if (singleDestinationData[0]?.countriesLists?.length > 1) {
                                                        setCheckCoverage(true)
                                                    }
                                                }} style={{ cursor: singleDestinationData[0]?.countriesLists?.length > 1 ? 'pointer' : '' }} className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="m-0 text-white"><i className="bi bi-globe me-2"></i>Coverage</p>
                                                    <p className="m-0 text-white fw-bold">{singleDestinationData[0]?.coverage} {singleDestinationData[0]?.countriesLists?.length > 1 && <InfoIcon sx={{ fontSize: '18px' }} />}  </p>
                                                </div>
                                                <div className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="m-0 text-white"><i class="bi bi-wifi me-2"></i>Data</p>
                                                    <p className="m-0 text-white fw-bold">{singleDestinationData[0]?.data}</p>
                                                </div>
                                                <div className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="m-0 text-white"><i className="bi bi-calendar me-2"></i>Validity</p>
                                                    <p className="m-0 text-white fw-bold">{singleDestinationData[0]?.validity}</p>
                                                </div>
                                                <div className="m-0 text-white d-flex justify-content-between align-items-center">
                                                    <p className="m-0 text-white"><i className="bi bi-tag me-2"></i> Price</p>
                                                    <span className="fw-bold text-white fs-5">{singleDestinationData[0]?.price}</span>
                                                </div>
                                                {/*<div className="m-0 text-white operators">*/}
                                                {/*    <p className="m-0 text-white"><i className="bi bi-bar-chart-fill me-2"></i> Operator</p>*/}
                                                {/*    <div className="d-flex flex-wrap">*/}
                                                {/*        {singleDestinationData[0]?.operatorList?.map((operatorList, index) => <p key={index} className="text-white mb-0 me-3"><i class="bi bi-sim-fill me-1 secondary-text"></i><small>{operatorList.operatorName}</small></p>)}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal_body_content">
                                        <h6 style={{ display: 'none' }} className="text-black-50">Available Top-up Packages (3)</h6>
                                        <div style={{ display: 'none' }} className="slider-container mt-3 mt-md-4 mb-5">
                                            <Slider {...settings}>
                                                <div>
                                                    <div className="package_card light-bg">
                                                        <div className="top-up_plan">
                                                            1 GB - 7 GB
                                                        </div>
                                                        <div className="p-3">
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-globe me-2"></i>Coverage</p>
                                                                <p className="m-0 fw-bold">Indonessia</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-arrow-down-up me-2"></i>Data</p>
                                                                <p className="m-0 fw-bold">2 GB</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-calendar me-2"></i>Validity</p>
                                                                <p className="m-0 fw-bold">7 Days</p>
                                                            </div>
                                                            <div className="m-0 d-flex justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-tag me-2"></i> Price</p>
                                                                <span className="fw-bold primary-text fs-5">$4.50 USD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="package_card light-bg">
                                                        <div className="top-up_plan">
                                                            1 GB - 7 GB
                                                        </div>
                                                        <div className="p-3">
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-globe me-2"></i>Coverage</p>
                                                                <p className="m-0 fw-bold">Indonessia</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-arrow-down-up me-2"></i>Data</p>
                                                                <p className="m-0 fw-bold">2 GB</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-calendar me-2"></i>Validity</p>
                                                                <p className="m-0 fw-bold">7 Days</p>
                                                            </div>
                                                            <div className="m-0 d-flex justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-tag me-2"></i> Price</p>
                                                                <span className="fw-bold primary-text fs-5">$4.50 USD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="package_card light-bg">
                                                        <div className="top-up_plan">
                                                            1 GB - 7 GB
                                                        </div>
                                                        <div className="p-3">
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-globe me-2"></i>Coverage</p>
                                                                <p className="m-0 fw-bold">Indonessia</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-arrow-down-up me-2"></i>Data</p>
                                                                <p className="m-0 fw-bold">2 GB</p>
                                                            </div>
                                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-calendar me-2"></i>Validity</p>
                                                                <p className="m-0 fw-bold">7 Days</p>
                                                            </div>
                                                            <div className="m-0 d-flex justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-tag me-2"></i> Price</p>
                                                                <span className="fw-bold primary-text fs-5">$4.50 USD</span>
                                                            </div>
                                                            <div className="m-0 d-flex justify-content-between align-items-center">
                                                                <p className="m-0"><i className="bi bi-tag me-2"></i> Price</p>
                                                                <span className="fw-bold primary-text fs-5">$4.50 USD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                        <div className="d-flex justify-content-between gap-4 specs_wrap mt-3">
                                            <div className='w-100'>
                                                <h5>Supported Countries</h5>
                                                <div className="card">
                                                    <ul>
                                                        {
                                                            singleDestinationData[0]?.countriesLists?.map((ele, index) => (
                                                                <li key={index} className="d-flex justify-content-between gap-2 p-2 light-bg rounded mb-1">
                                                                    <div>
                                                                        <p className="fw-bold mb-1">{ele.countryName}</p>
                                                                    </div>
                                                                    <div className="icon">
                                                                        {/*<i className="bi bi-clipboard-check-fill"></i>*/}
                                                                        {
                                                                            ele.countryFlag && <img
                                                                                alt={ele.countryName}
                                                                                src={ele.countryFlag}
                                                                                style={{ width: "32px", height: "30px" }}
                                                                            />
                                                                        }

                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div >
                                                <h5>Additional Information</h5>
                                                <div className="card">
                                                    <ul>
                                                        <li onClick={() => setCheckNetwork(true)} className="d-flex gap-2 p-2 light-bg rounded mb-1 pointer">
                                                            <div className="icon">
                                                                <i class="bi bi-reception-4"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">Network</span>
                                                                <p className="fw-bold">{'Check Available Networks'}</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex gap-2 p-2 light-bg rounded mb-1">
                                                            <div className="icon">
                                                                <i className="bi bi-clipboard-check-fill"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">Plan Type</span>
                                                                <p className="fw-bold">{singleDestinationData[0]?.planType}</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex gap-2 p-2 light-bg rounded mb-1">
                                                            <div className="icon">
                                                                <i className="bi bi-ui-checks"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">Activation Policy</span>
                                                                <p className="fw-bold">{singleDestinationData[0]?.activationPolicy}</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex gap-2 p-2 light-bg rounded mb-1">
                                                            <div className="icon">
                                                                <i className="bi bi-router-fill"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">IP Routing</span>
                                                                <p className="fw-bold">{singleDestinationData[0]?.ipRouting}</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex gap-2 p-2 light-bg rounded mb-1">
                                                            <div className="icon">
                                                                <i className="bi bi-fingerprint"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">eKYC (Identity Verification)</span>
                                                                <p className="fw-bold">{singleDestinationData[0]?.eKYCIdentityVerification}</p>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex gap-2 p-2 light-bg rounded mb-1">
                                                            <div className="icon">
                                                                <i className="bi bi-wallet2"></i>
                                                            </div>
                                                            <div>
                                                                <span className="text-black-50 small fw-bold">Top-Up Option</span>
                                                                <p className="fw-bold">{singleDestinationData[0]?.topUpOption}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>     </div>
                                        </div>
                                        <p className="fw-bold small mt-3"><i className="bi bi-toggle2-off me-2"></i>{singleDestinationData[0]?.lastLine}</p>
                                    </div>
                                </div >
                                <div className="modal-footer justify-content-between px-4">
                                    <h3 className="primary-text">{singleDestinationData[0]?.price}</h3>
                                    <button type="button" className="mybtn secondary-bg px-5" onClick={() => handleBuyNow()}>Buy</button>
                                </div>
                            </div >
                            :
                            <div className="modal-content">
                                <button type="button" onClick={onClose} className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div style={{ minHeight: "80vh" }}> <MiniLoader top={'50%'} /></div>
                            </div>
                    }
                </div >
            </div >
            <div onClick={onClose} className={`modal_overlay ${detailsModal ? 'd-block' : 'd-none'}`}></div>

            {
                checkNetwork &&
                <Modal
                    open={checkNetwork}
                    onClose={() => setCheckNetwork(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                ><>
                        <Box sx={style}>
                            <div className='mb-2'>
                                <div className='d-flex justify-content-between'>
                                    <h5 style={{ marginBottom: "0px" }}>Network</h5>
                                    <button onClick={() => setCheckNetwork(false)} className="muiModal-close-btn">X</button>
                                </div>
                                <p>The speed may vary depending on the location.</p>
                            </div>
                            <hr></hr>
                            {
                                singleDestinationData[0]?.operatorList?.map((ele, index) => (
                                    <li key={index} className="d-flex justify-content-between align-items-center gap-2 p-2 light-bg rounded mb-1">
                                        <div className='d-flex justify-content-between  gap-2'>
                                            {
                                                ele.countryFlag &&
                                                <img
                                                    alt={ele.country}
                                                    src={ele.countryFlag}
                                                    style={{ width: "32px", height: "30px" }}
                                                />
                                            }

                                            <p className="fw-bold mb-1">{ele.country}</p>
                                        </div>
                                        <div className="ntwrk">
                                            {
                                                ele?.operatorNames?.map((elem, imdex) => (
                                                    <div className="d-flex gap-1 justify-content-end align-items-center" key={imdex}>
                                                        <p className="mb-1 ntwrk_oprtr text-truncate">{elem.operatorName}</p>
                                                        <Tooltip title={elem.operatorName} placement="top">
                                                            <InfoOutlinedIcon sx={{ fontSize: '15px' }} />
                                                        </Tooltip>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                        </Box>
                    </>
                </Modal>
            }
            {
                checkCoverage &&
                <Modal
                    open={checkCoverage}
                    onClose={() => setCheckCoverage(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                ><>
                        <Box sx={style}>
                            <div className='mb-2'>
                                <div className='d-flex justify-content-between'>
                                    <h5 style={{ marginBottom: "0px" }}>More Information</h5>
                                    <button onClick={() => setCheckCoverage(false)} className="muiModal-close-btn">X</button>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="d-flex justify-content-between gap-4 specs_wrap mt-3 mb-4">
                                <div className='w-100'>
                                    <h6>Supported Countries</h6>
                                    <div style={{ overflow: "auto", height: "34vh" }} className="card">
                                        <ul>
                                            {
                                                singleDestinationData[0]?.countriesLists?.map((ele, index) => (
                                                    <li key={index} className="d-flex justify-content-between gap-2 p-2 light-bg rounded mb-1">
                                                        <div>
                                                            <p className="fw-bold mb-1">{ele.countryName}</p>
                                                        </div>
                                                        <div className="icon">
                                                            {/*<i className="bi bi-clipboard-check-fill"></i>*/}
                                                            {
                                                                ele.countryFlag && (
                                                                    <img
                                                                        alt={ele.countryName}
                                                                        src={ele.countryFlag}
                                                                        style={{ width: "32px", height: "30px" }}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div >
                                    <h6>Avilable Network</h6>
                                    <div style={{ overflow: "auto", height: "34vh" }} className="card">
                                        {
                                            singleDestinationData[0]?.operatorList?.map((ele, index) => (
                                                <li key={index} className="d-flex justify-content-between align-items-center gap-2 p-2 light-bg rounded mb-1">
                                                    <div className='d-flex justify-content-between  gap-2'>
                                                        {
                                                            ele.countryFlag &&
                                                            <img
                                                                alt={ele.country}
                                                                src={ele.countryFlag}
                                                                style={{ width: "32px", height: "30px" }}
                                                            />
                                                        }
                                                        <p className="fw-bold mb-1">{ele.country}</p>
                                                    </div>
                                                    <div className="ntwrk">
                                                        {
                                                            ele?.operatorNames?.map((elem, imdex) => (
                                                                <div className="d-flex gap-1 justify-content-end align-items-center" key={imdex}>
                                                                    <p className="mb-1 ntwrk_oprtr text-truncate">{elem.operatorName}</p>
                                                                    <Tooltip title={elem.operatorName} placement="top">
                                                                        <InfoOutlinedIcon sx={{ fontSize: '15px' }} />
                                                                    </Tooltip>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </div>     </div>
                            </div>

                        </Box>
                    </>
                </Modal>
            }
        </>
    )
}

export default ModalData;